import {
  Button,
  Spinner,
  VisuallyHidden,
  type ButtonProps,
} from "@radix-ui/themes";
import {
  ChatBubbleIcon,
  CrossCircledIcon,
  Pencil2Icon,
} from "@radix-ui/react-icons";
import React from "react";

const commonStyles = { style: { cursor: "pointer" } };

export type EditButtonProps = ButtonProps & {
  /** Only show an icon */
  iconOnly?: boolean;
  entity: string;
};

export const EditButton = React.forwardRef<HTMLButtonElement, EditButtonProps>(
  function EditButton({ iconOnly = false, ...props }, ref) {
    return (
      <Button ref={ref} {...{ variant: "soft", ...commonStyles, ...props }}>
        <Pencil2Icon />
        {iconOnly ? <VisuallyHidden>Edit</VisuallyHidden> : "Edit"}
        <VisuallyHidden>{props.entity}</VisuallyHidden>
      </Button>
    );
  },
);

export const ReviewButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function ReviewButton({ ...props }, ref) {
    return (
      <Button ref={ref} {...{ variant: "soft", ...commonStyles, ...props }}>
        <ChatBubbleIcon />
        Review
      </Button>
    );
  },
);

export const CancelButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function CancelButton(props, ref) {
    return (
      <Button
        ref={ref}
        {...{ variant: "soft", color: "gray", ...commonStyles, ...props }}
        // we want to avoid the default of "submit" since it'll submit forms!
        type="button"
      >
        Cancel
      </Button>
    );
  },
);

export type SaveButtonProps = ButtonProps & {
  loading?: boolean;
};

export const SaveButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function SaveButton(props, ref) {
    let { loading, ...rest } = props;

    return (
      <Button ref={ref} {...{ name: "intent", ...commonStyles, ...rest }}>
        {loading && <Spinner />}
        Save
      </Button>
    );
  },
);

export type DeleteButtonProps = ButtonProps & {
  /** What are we deleting? Ideally this is distinct in a list so accessibility
   * tools can distinguish between different delete buttons. */
  label: string;

  /** Only show a trash icon */
  iconOnly?: boolean;

  /** Show that this delete button is loading */
  loading?: boolean;
};

export const DeleteButton = React.forwardRef<
  HTMLButtonElement,
  DeleteButtonProps
>(function DeleteButton({ label, iconOnly = false, ...props }, ref) {
  let caption = `Delete ${label}`;
  let { loading, ...rest } = props;

  return (
    <Button
      ref={ref}
      {...{ color: "red", variant: "soft", ...commonStyles, ...rest }}
    >
      {loading ? <Spinner /> : <CrossCircledIcon />}
      {iconOnly ? <VisuallyHidden>{caption}</VisuallyHidden> : caption}
    </Button>
  );
});
